import React from "react"
import loadable from "@loadable/component"

const Layout = loadable(() => import("@common/Layout/Layout"))

const ExtendedReality = loadable(() => import("@components/ExtendedReality"));

const SEO = loadable(() => import("@components/Common/SEO/SEO"))

const ExtendedRealityPage = ({ path }) => {
  return (
    <>
      <SEO
        title="XR development services for innovative companies."
        titleTemplate="Impressit"
        description="Elevate your business with custom XR development solutions. Partner with a leading XR development company specializing in Apple Vision Pro."
        path={path}
      />
      <Layout path={path} isNoPadding>
        <ExtendedReality />
      </Layout>
    </>
  )
}

export default ExtendedRealityPage;
